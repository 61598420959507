import classNames from 'classnames';
import { useLocation } from 'found';
import React, { useEffect, useRef } from 'react';

import './PageContainer.scss';

interface Props {
  children: React.ReactNode;
  center?: boolean;
  className?: string;
  dataTestId?: string;
}

const rootClass = 'PageContainer';

const PageContainer = ({
  dataTestId = rootClass,
  center,
  className,
  children,
  ...props
}: Props) => {
  const ref = useRef<any>(null);
  const location = useLocation();

  const executeScroll = () => ref.current.scrollTo(0, 0);

  // Scroll top top of container on page change

  useEffect(() => {
    if (location.pathname.includes('/-/eulas/latest/')) {
      executeScroll();
    }
  }, [location.pathname]);

  return (
    <div
      id={rootClass}
      data-testid={dataTestId}
      className={classNames(
        rootClass,
        className,
        center && `${rootClass}--center`,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
};

export default PageContainer;
